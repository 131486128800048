// Vendors
import Vue from 'vue';

// Custom Vue Components
import stickyTop from './components/stickyTop.vue';

// Vue vendor modules
import VueScrollTo from "vue-scrollto";
import VueSlickCarousel from 'vue-slick-carousel'

// Other JS vendor modules
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import yall from 'yall-js'

class App {

  constructor() {

    Vue.use(VueScrollTo, {
      container: "body",
      duration: 500,
      easing: "ease",
      offset: 0,
      force: true,
      cancelable: true,
      x: false,
      y: true
    });
  }

  init() {

    document.addEventListener("DOMContentLoaded", yall);

    new Vue({
      el: '#app',
      delimiters: ['<%', '%>'],
      components: {
        'stickyTop': stickyTop,
        VueSlickCarousel,
      },

      data() {
        return {
          carouselSettings:{
            "accessibility": true,
            "centerMode": true,
            "focusOnSelect": false,
            "infinite": true,
            "slidesToShow": 6,
            "slidesToScroll": 1,
            "speed": 500,
            "arrows": false,
            "autoplay": true,
            "autoplaySpeed": 2000,
            "responsive": [
              {
                "breakpoint": 1024,
                "settings": {
                  "slidesToShow": 4,
                }
              },
              {
                "breakpoint": 640,
                "settings": {
                  "slidesToShow": 3,
                }
              },
              {
                "breakpoint": 480,
                "settings": {
                  "slidesToShow": 2,
                }
              }
            ]
          },
          menuIsActive: false,
          window: {
            width: 0,
            height: 0,
          },
          maxNavHeight: null,
          maxNavHeightStyle: '',
          subNavsContainers: [],
          subNavs: [],
          menuTarget: null,
        }
      },

      mounted() {
        this.menuTarget = document.getElementById('menu');
        this.subNavsContainers = this.$el.querySelectorAll('.c-nav-menu-item-has-sub');
        this.subNavs = this.$el.querySelectorAll('.c-nav-menu-sub');
        this.subNavsContainers.forEach((element) => {
          const node = document.createElement('span');
          node.classList.add("toggle-link");
          element.insertBefore(node, element.firstChild);
        })

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      },

      watch: {

        menuIsActive() {
          if (this.menuIsActive) {
            this.maxNavHeight = this.window.height - this.$refs.nav.clientHeight;
          } else {
            this.maxNavHeight = 0;
          }
        },

        maxNavHeight() {
          this.maxNavHeightStyle = this.window.width <= 768 ? `${this.maxNavHeight}px`: '';
        },

      },

      methods: {

        toggleActive() {
          // https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177
          this.menuIsActive = !this.menuIsActive
          if (this.menuIsActive) {
            disableBodyScroll(this.menuTarget);
          } else  {
            enableBodyScroll(this.menuTarget);
          }
        },

        menuClicked(event) {
          let targetClassNames = event.target.className.split(" ");
          targetClassNames.filter(e => {
            if (e === "toggle-link"){
              event.target.classList.toggle('active')
              const subMenu = event.target.parentNode.querySelector('ul');
              subMenu.classList.toggle('active');
             }
          });
        },

        handleResize() {
          this.window.width = window.innerWidth;
          this.window.height = window.innerHeight;
          if (this.window.width >= 768) {
            this.menuIsActive = false;
            this.maxNavHeight = null;
            this.subNavs.forEach((element) => {
              element.classList.remove('active');
            })
          } else {
            this.maxNavHeight = 0;
          }
        }
      }
    });
  }
}

const app = new App();
app.init();