<template>
  <div class="c-sticky-top" :style="myStyle">
    <slot></slot>
  </div>
</template>

<script>
  export default {

    data() {
      return {
        myStyle: {},
        scrollY: 0,
        didScroll: false,
        lastScrollTop: 0,
        delta: 5,
        rectHeight: 0
      }
    },

    computed: {

      isHome() {
        return
      }
    },

    mounted() {
      const that = this;
      this.originalTop = this.$el.offsetTop;
      this.scrollY = document.documentElement.scrollTop;

      this.setPaddingTop()

      window.addEventListener('resize', this.setPaddingTop());
      window.addEventListener('scroll', (event) => {
        this.scrollY = Math.round(window.scrollY);
      });

      setInterval(function () {
        if (that.didScroll) {
          that.setSticky();
          that.didScroll = false;
        }
      }, 333);
    },

    watch: {
      scrollY() {
        this.didScroll = true;
      }
    },

    methods: {
      setSticky() {
        // Make sure they scroll more than delta
        if (Math.abs(this.lastScrollTop - this.scrollY) <= this.delta)
          return;
        if (this.scrollY > this.lastScrollTop && this.scrollY > this.rectHeight) {
          // Scroll Down
          this.$set(this.myStyle, 'top', `-${this.rectHeight}px`);
        } else {
          // Scroll Up
          this.$delete(this.myStyle, 'top');
        }
        this.lastScrollTop = this.scrollY;
      },
      setPaddingTop() {
        this.rectHeight = this.$el.getBoundingClientRect().height;
        if (document.body.classList.contains('home')) {
          document.getElementsByClassName("c-header")[0].setAttribute("style", `padding-top: ${this.rectHeight + 1}px;`);
        } else {
          document.getElementsByClassName("o-body")[0].setAttribute("style", `padding-top: ${this.rectHeight + 1}px;`);
        }
      }
    }
  };

</script>

<style>
  .c-sticky-top {
    transition: all 0.333s ease-in-out;
    z-index: 99;
    position: fixed;
    top: 0;
    width: 100%;
  }
</style>
